body,
html {
  font-family: "Roboto", sans-serif;
}

/*body.modal-open > :not(.modal) {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}*/

.a-link {
  text-decoration: none !important;
  color: #8b919c !important;
  border: none;
}


.ja-bg-secondary {
  background-color: $ja-secondary-color !important;
}

.ja-bg-search-bar {
  background-color: $ja-search-bar-color !important;
}

.ja-bg-gray {
  background-color: #dfdfdf;
}

.ja-border-gray {
  border: 2px solid $ja-color-gray;
}

.ja-bg-dysphagia {
  background-color: #5da2e7;
}

.font-size-18 {
  font-size: 1.2rem;
}

.ja-text-gray {
  color: #8b9198;
}

a.ja-text-gray {
  &:hover {
    color: #1b3f63 !important;
  }
}

.access-code-admin-look,
.access-code-professional {
  &:hover {
    & span {
      color: #1b3f63 !important;
    }
  }
}

.ja-bg-light {
  background-color: #eeeeee;
}

.progressTeil {
  padding: 10px 0 10px 0;
}

.progressBarContainer {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch
}

.vvv {
  text-align: center;
  width: 100%
}

.progressBarMargins {
  margin: 0px 0 48px 0
}

.search-icon {
  position: absolute;
  top: 12px;
  left: 30px;
}

.search-input::placeholder {
  color: #8b9198;
  font-weight: 500;
  font-size: 17px;
  //padding-left: 20px;
}

.assessment-icon {
  background-color: #ffffff;
  border-radius: 10px;
  width: 37px;
  height: 35px;
  display: inline-block;
  line-height: 35px;
  margin: 5px;

  & > div {
    color: #ffffff;
    font-weight: 500;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    line-height: 25px;
    text-align: center;
    position: relative;
    top: 5px;
    left: 6px;
  }
}

.ja-bg-status {
  background-color: $ja-color-status;
}

.ja-color-speech {
  color: $ja-speech !important;
}

.ja-bg-speech {
  background-color: $ja-speech;
}

.ja-border-speech {
  border: 2px solid $ja-speech;
}

.ja-color-speech_dysphagia {
  color: $ja-speech !important;
}

.ja-bg-closed {
  background-color: $ja-closed;
}

.ja-border-closed {
  border: 2px solid $ja-closed;
}

.ja-border-incompleted {
  border: 2px solid #fd9268;
}

.ja-color-closed_dysphagia {
  color: $ja-closed !important;
}

.ja-bg-speech_dysphagia {
  background-color: $ja-speech;
}

.ja-border-speech_dysphagia {
  border: 2px solid $ja-speech;
}

.ja-color-speech_dysarthria {
  color: $ja-speech !important;
}

.ja-bg-speech_dysarthria {
  background-color: $ja-speech;
}

.ja-border-speech_dysarthria {
  border: 2px solid $ja-speech;
}

.ja-color-speech_sialorrhoea {
  color: $ja-speech !important;
}

.ja-bg-speech_sialorrhoea {
  background-color: $ja-speech;
}

.ja-bg-symptom-speech {
  background-color: rgba(93, 162, 231, 0.1);
  color: #8b9198;
}

.ja-bg-symptom-speech_dysphagia {
  background-color: rgba(93, 162, 231, 0.1);
  color: #8b9198;
}

.ja-bg-symptom-speech_dysarthria {
  background-color: rgba(93, 162, 231, 0.1);
  color: #8b9198;
}

.ja-bg-symptom-speech_sialorrhoea {
  background-color: rgba(93, 162, 231, 0.1);
  color: #8b9198;
}

.ja-bg-symptom-physio {
  background-color: rgba(72, 142, 121, 0.1);
  color: #8b9198;
}

.ja-bg-symptom-occupational {
  background-color: rgba(255, 192, 0, 0.1);
  color: #8b9198;
}

.ja-border-speech_sialorrhoea {
  border: 2px solid $ja-speech;
}

.ja-color-physio {
  color: $ja-physio !important;
}

.ja-bg-physio {
  background-color: $ja-physio;
}

.opacity--8 {
  opacity: 0.8 !important;
}

.opacity--7 {
  opacity: 0.7 !important;
}

.opacity--6 {
  opacity: 0.6 !important;
}

.checkbox-1x {
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}

.checkbox-2x {
  transform: scale(2);
  -webkit-transform: scale(2);
}

.checkbox-round {
  width: 1.5rem;
  height: 1.5rem;
}

//.checkbox-round:checked {
//  background-color: gray;
//}

.ja-border-physio {
  border: 2px solid $ja-physio;
}

.ja-color-occupational {
  color: $ja-occupational !important;
}

.ja-bg-occupational {
  background-color: $ja-occupational;
}

.ja-border-occupational {
  border: 2px solid $ja-occupational;
}

.ja-color-consensus {
  color: $ja-consensus;
}

.ja-bg-consensus {
  background-color: $ja-consensus;
}

.ja-border-consensus {
  border: 2px solid $ja-consensus;
}

.ja-bg-warning {
  background-color: $ja-consensus;
  opacity: 1 !important;
}

.ja-border-warning {
  border: 2px solid $ja-consensus;
}

.ja-color-closed {
  color: $ja-closed;
}

.ja-bg-closed {
  background-color: $ja-closed;
}

.ja-border-closed {
  border: 2px solid $ja-closed;
}

.ja-color-aborted {
  color: $ja-closed;
}

.ja-bg-aborted {
  background-color: $ja-closed;
}

.ja-border-aborted {
  border: 2px solid $ja-closed;
}

.header-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-evenly;

  & > * {
    flex: 1 1 200px;
  }
}

.outline-none {
  outline: 0 !important;
}

.ja-table {
  thead tr {
    display: flex;
    min-width: 500px;
  }

  thead th {
    color: #8b9198;
    border-top: 0;
    //padding-left: 7rem;
    flex: 1 1 100%;
  }

  td {
    border-bottom: 0;
    border-top: 0;
    padding: 7px 0;
  }
}

.ja-bg-form-color {
  background-color: $ja-form-text-color !important;
}

.ja-bg-primary {
  background-color: $ja-primary-color !important;
}

.cursor-pointer {
  cursor: pointer;
}

.tele-icon-size {
  font-size: 25px;
}

.icons-container {
  display: flex;
  justify-content: center; /* Center content horizontally */

}

.icons-wrapper {
  display: flex;
  justify-content: flex-end; /* Center content horizontally */

  width: 170px;
  white-space: nowrap; /* Prevents icons from wrapping */

}

.icons-alignment {
  display: flex;
  justify-content: flex-end; /* Center content horizontally */

}

.tele-active-icon {
  color: #2178B9
}



.tele-delete-icon {
  color: #AF2525;
}

.admin-navbar {
  li > .nav-link {
    color: #1b3f63;
    font-size: 18px;
    font-weight: 500;
  }

  li {
    overflow-x: auto;
  }

  li.active {
    border-bottom: 2px solid #1b3f63;

    .nav-link {
      color: #1b3f63 !important;
    }
  }
}

.ja-text {
  color: $ja-form-text-color !important;
}

.border-radius-20 {
  border-radius: 20px !important;
}

.border-radius-20-top {
  border-radius: 20px 20px 0 0 !important;
}

.border-radius-40 {
  border-radius: 40px !important;
}

.rounded-top-20 {
  border-radius: 20px 20px 0 0 !important;
}

.rounded-bottom-20 {
  border-radius: 0 0 20px 20px !important;
}

.color-8B9198 {
  color: #8b9198;
}

.border-radius-top {
  border-radius: 20px 20px 0 0 !important;
}

.btn-login {
  background-color: $ja-orange-color;
  color: $ja-form-text-color;
  border-radius: 0;
  font-weight: 600;
}

.text-orange {
  color: $ja-orange-color !important;
}

.text-search-bar {
  color: $ja-primary-color;
}

.no-search-result-message {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.no-search-result-message > h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 45px;
  color: #8b9198;
  text-align: center;
  align-self: center;
  margin: 0 auto;
  padding-bottom: 30px;
}

.no-search-result-message > h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: #8b9198;
  align-self: center;
  text-align: center;
  margin: 0 auto;
}

.loading-spinner-container {
  display: flex;
  height: 50vh;
}

.loading-spinner-patient {
  display: flex;
  height: 35vh;
}

.err-message-container {
  display: flex;
  height: 50vh;
}

.text-secondary {
  color: $ja-primary-color !important;
}

.btn.btn-search {
  .text-secondary {
    opacity: 0.7;

    &:hover,
    &:focus,
    &:active {
      opacity: 1;
    }
  }
}

.sidebar-link-icon {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  border: 3px solid #000;
  text-decoration: none !important;
  justify-content: center;
  margin: 0 auto;
}

.search-bar-icons {
  .fas {
    cursor: pointer;
    //opacity: 0.5;
    &.text-secondary:hover {
      //opacity: 1;
      color: $ja-form-text-color !important;
    }

    &.active {
      //opacity: 1;
      color: $ja-form-text-color !important;
    }
  }
}

.fas-icon-check {
  //top: 9px;
  right: -15px;
}

.top-9 {
  top: 10px !important;
}

.top-10 {
  top: 10px !important;
}

.btn-search {
  width: 30px;
  height: 30px;
  background-color: #d6dbe3;
  cursor: pointer;

  &:hover {
    background-color: $ja-form-text-color;
  }

  .ja-bg-form-color:hover {
    background-color: #6c757d;
  }
}

span.badge-notification {
  position: absolute;
  background-color: #dc3545;
  width: 1.25rem;
  border-radius: 50%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  font-weight: 700;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-left: -10px;
  margin-top: 15px;
}

.badge-custom {
  font-size: 12px !important;
  right: 7px;
  top: 2px;
}

.icon-search {
  top: 5px;
  left: 9px;
}

li.sidebar-item.active {
  background-color: $ja-form-text-color;
  width: 100%;
  padding: 5px 0;
  transition: 0.1s ease;
}

.footer {
  bottom: 0;
  position: fixed;
  background-color: #ffffff;
  width: calc(100vw - #{$ja-sidebar-width});
}

.footer-new {
  bottom: 0;
  position: fixed;
  background-color: #ffffff;
  //width: calc(100vw - #{$sidebar-width});
  //width: calc(100% - #{$sidebar-width});
  width: 100%;
  right: 0;
  margin: 0;
}

.shadow-sm-top {
  box-shadow: 0 0 0.25rem 0.125rem rgba(0, 0, 0, 0.075) !important;
}

.patient-link:hover {
  background-color: rgba(139, 145, 152, 0.4);
  text-decoration: none;
}

.shadow-sm-bottom {
  box-shadow: 0.25rem 0.125rem 0 0 rgba(0, 0, 0, 0.075) !important;
}

#ja-header {
  height: $ja-header-height;
}

#ja-content {
  height: calc(100vh - 60px);
}

.pb-6 {
  //padding-bottom: 4rem!important;
}

#my-menu {
  width: $ja-sidebar-width;
}

.btn-footer {
  background-color: $ja-form-text-color;
  color: #fff;
  text-align: center;

  border: 1px solid #2278b9;
  background-color: #2278b9;

  &:hover,
  &:focus,
  &:active {
    background-color: #273c75;
    color: #fff;
    box-shadow: none;
  }
}

//.ja-symptom-height{
//  height: 50px;
//}

.ja-symptom-title {
  font-size: 24px;
  font-weight: 400;
}

.quickcard-progress {
  li {
    width: 2em;
    height: 2em;
    text-align: center;
    line-height: 2em;
    border-radius: 1em;
    background: dodgerblue;
    margin: 0 1em;
    display: inline-block;
    color: white;
    position: relative;
  }

  li::before {
    content: "";
    position: absolute;
    top: 0.9em;
    left: -4em;
    width: 4em;
    height: 0.2em;
    background: dodgerblue;
    z-index: -1;
  }

  li:first-child::before {
    display: none;
  }

  .active {
    background: dodgerblue;
  }

  .active ~ li {
    background: lightblue;
  }

  .active ~ li::before {
    background: lightblue;
  }
}

.min-height-200 {
  min-height: 200px !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

span.requiredLabel {
  position: absolute;
  right: 0;
  top: 10px;
}

button.btn.btn-add-organisation {
  position: absolute;
  top: -3px;
  right: -35px;
}

.btn-secondary,
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #8b919a;
  border-color: #8b919a;
}

span.requiredCheckboxLabel {
  position: absolute;
  top: 7px;
  left: 52px;
  font-size: 15px;
}

.add-left-7 {
  left: 7px !important;
}

.title-separator {
  position: relative;
  border: 1px solid #e8eaf0;
  height: 1px;
  margin-bottom: 25px;
}

.title-separator span {
  position: absolute;
  font-weight: 500;
  line-height: 22px;
  font-size: 26px;
  color: $ja-form-text-color;
  bottom: -9px;
  background-color: #fff;
  padding-right: 12px;
}

.add-new-link {
  position: absolute;
  right: 14px;
  top: -18px;
  background: white;
  padding-left: 1em;
}

.z-index-1 {
  z-index: 1;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

//input:read-only, textarea:read-only {
//  background-color: #e9ecef ;
//}

read-only-input {
  background-color: #e9ecef !important;
}

.login-tab {
  color: #dfdfdf;
}

.login-tab.active {
  color: #1b3f63;
  font-weight: 700;
  border-bottom: 2px solid #1b3f63;
}

.login-input {
  border: 1px solid #919fb3;
  box-sizing: border-box;
  border-radius: 78px;
  //padding-right: 30px;
}

/*.tele-spacing-container {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: center;
  gap: 5px;

}*/

.tele-spacing {
  width: 150px;
  flex: 1
}

.black {
  color: #333333
}

.text-dark-blue {
  color: #1b3f63;
}

.d-flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.border-radius-78 {
  border-radius: 78px;
}

.bg-login {
  background-color: #2178b9;
}

.h-90 {
  height: 90%;
}

.register-link {
  color: #8b9198;
  text-decoration: underline;
  text-align: center;
}

@media (max-width: 576px) {
  .register-link {
    max-width: 80px;
  }
}

.bg-login-img {
  background: url("../img/login.png");
  //position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-color: steelblue;
}

.img-avatar {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  background-color: #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-title {
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 29px;
  color: #1b3f63;
}

.card-downloads a,
.card-downloads a:hover,
.card-downloads a:focus {
  text-decoration: none;
  color: #8b9198;
}

.card-main-downloads {
  min-height: 330px;
  overflow-y: auto;
  border: 2px solid #dfdfdf;
  border-radius: 25px;
}

.card-main-videos {
  height: 100%;
  overflow-y: auto;
  border: 2px solid #dfdfdf;
  border-radius: 25px;
}

.card-main-downloads-row {
  border: 0px solid #dfdfdf;
  border-radius: 0px;
}

.card-headline {
  background-color: #dfdfdf;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  padding: 10px 0px;
}

.card-list-title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-card-headline {
  background-color: #dfdfdf;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 20px 20px 0px 0px;
  padding: 10px 0px;
  min-height: 70px;
}

.card-main-downloads-row > .card-headline {
  background-color: #dfdfdf;
  color: #8b9198;
  font-weight: bold;
  text-align: center;
  padding: 15px 0px;
  border-radius: 20px 0px 0px 20px;
}

.card-main-downloads-row > .card-comment {
  text-align: center;
}

.card-comment,
.card-additional {
  color: #8b9198;
  font-weight: bold;
  padding: 15px;
  text-align: left;
}

.thumbnail {
  border-radius: 20px;
  max-height: 173px;
  width: 100%;
}

.thumbnail-list {
  border-radius: 20px;
  width: 100%;
}

.card-additional-grid {
  color: #8b9198;
  font-weight: bold;
  padding: 15px 0px;
  text-align: left;
}

.card-additional span {
  text-decoration: underline;
}

.card-additional-extra {
  //min-height: 450px;
  word-break: inherit;
}

.card-border-other {
  border: 2px solid #8b9198 !important;
}

.card-other {
  background-color: #8b9198 !important;
}

.card-border-physio {
  border: 2px solid #488e79 !important;
}

.card-physio {
  background-color: #488e79 !important;
}

.card-border-occupational {
  border: 2px solid #ffc000 !important;
}

.card-occupational {
  background-color: #ffc000 !important;
}

.card-border-speech {
  border: 2px solid #5da2e7 !important;
}

.card-speech {
  background-color: #5da2e7 !important;
}

.card-row {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 10px;
  border: 2px solid #dfdfdf;
  border-radius: 25px;
}

.card-row > div:first-child {
  padding: 0 !important;
  background-color: #dfdfdf;
  border-radius: 20px 0px 0px 20px;
}

.card-row > div:last-child {
  padding: 0 !important;
}

.form-group {
  position: relative;
}

.form-group > div.toggle-field {
  position: absolute;
  right: 10px;
  top: 33px;
}

.form-group.admin-login > div.toggle-field {
  position: absolute;
  right: 10px;
  top: 33px;
}

/*#page-content-wrapper .card-min-height > div:nth-child(1) > div:nth-child(2) > div:nth-child(1) {
  pointer-events: none;
}*/

.field-disabled {
  pointer-events: none;
}

.field-disabled > div {
  background-color: #e9ecef;
}

#exampleModal .modal-content {
  border-radius: 25px !important;
}

#exampleModal .modal-header {
  border-radius: 23px 23px 0px 0px !important;
  background-color: #2278b9 !important;
}

#exampleModal .modal-header .close-span {
  color: white !important;
  margin-right: 5px !important;
  margin-bottom: 5px !important;
  font-weight: 300 !important;
}

#exampleModal .modal-footer button:first-child {
  margin-left: auto;
  margin-right: 10px;
  border-radius: 25px;
  border: 1px solid #8b9198 !important;
  color: #fff !important;
  background-color: #8b9198 !important;
}

#exampleModal .modal-footer button:last-child {
  margin-right: auto;
  margin-left: 10px;
  border-radius: 25px;
}

.logout-modal button:last-child {
  margin-right: auto;
  margin-left: 10px;
  border-radius: 25px;
  border: 1px solid #8b9198 !important;
  color: #fff !important;
  background-color: #8b9198 !important;
}

.exports-logo {
  width: 40%;
}

.hide-filter {
  display: none !important;
}

.hide-input-field {
  display: none !important;
}

.profile-header > a {
  text-decoration: none;
  color: #8b919c;
}

.profile-headline {
  font-size: 25px;
  color: #1b3f63;
}

.profile-subheadline {
  color: #8b9198;
}

#profile-main {
  margin-top: 20px;
}

#profile-main > div {
  padding: 0px 25px;
}

.profile-block-detail,
.profile-block-contact,
.profile-block-organization {
  background: #ffffff;
  border: 2px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 20px;
}

.profile-block-contact {
  padding: 20px 30px;
}

.profile-block-detail {
  padding: 30px;
}

.profile-block-organization {
  padding: 30px;
}

.profile-block-organization {
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 20px;
}

.profile-block-detail > div:first-child > i {
  color: #fff;
  background-color: #8b9198;
  border-radius: 50%;
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-block-detail > div:nth-child(2) > div:not(:first-child) {
  margin: 40px 0px;
  position: relative;
}

.profile-block-organization > div:first-child > i {
  color: #fff;
  background-color: #8b9198;
  padding: 40px;
  border-radius: 50%;
}

.profile-block-organization > div:nth-child(2) > div:not(:first-child) {
  margin: 20px 0px;
  position: relative;
}

.profile-block-contact > div {
  margin: 10px 0px;
  position: relative;
  display: flex;
}

.profile-block-organization > div > div {
  position: relative;
  display: flex;
}

.profile-block-detail > div:nth-child(2) > div > i,
.profile-block-contact > div > i,
.profile-block-organization > div:nth-child(2) > div > i {
  color: #8b9198;
  padding-right: 10px;
  flex: 1;
}

.profile-block-organization > div:nth-child(2) > div > span {
  flex: 5;
  align-self: center;
}

.profile-block-contact > div > span,
.profile-block-organization > div > span {
  max-width: 80%;
  word-break: break-word;
  flex: 4;
  margin-top: 2.5px;
}

.profile-block-detail > div:nth-child(2) > div > span,
.profile-block-contact > div > span,
.profile-block-organization > div > div > span {
  /*position: absolute;
  top: 5px;
  left: 60px;*/
  color: #8b9198;
}

.profile-block-contact > div:nth-child(5) > i,
.profile-block-contact > div:nth-child(6) > i,
.profile-block-contact > div:nth-child(7) > i,
.profile-block-organization > div > div:nth-child(4) > i,
.profile-block-organization > div > div:nth-child(5) > i,
.profile-block-organization > div > div:nth-child(6) > i {
  visibility: hidden;
}

#profile-sharing i {
  color: #1b3f63;
}

.text-center section section div {
  box-shadow: rgba(255, 253, 253, 0.5) 0px 0px 0px 5px inset !important;
}

.patient-access-codes i {
  color: #1b3f63;
}

.profile-sharing-code {
  margin-top: 20px;
  margin-left: 0px;
  margin-right: 0px;
  padding: 25px;
  border: 2px solid #dfdfdf;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 20px;
}

//.profile-sharing-qr-code {
//  width: 150px;
//  margin: auto;
//}

.qr-code-icon-size {
  font-size: 10rem;
}

.qr-code-icon-size-sm {
  font-size: 5rem;
}

@media (max-width: 800px) {
  .qr-code-icon-size {
    font-size: 7rem;
  }
}

.profile-sharing-code > div > div {
  padding: 20px;
}

//.profile-sharing-access-code {
//  width: 75%;
//  margin: auto;
//}

.profile-sharing-access-code span {
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #1b3f63;
}

.profile-sharing-access-code input {
  border: 2px solid #919fb3;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 20px;
  width: 70%;
  outline: none;
}

#questionnaire {
  margin: 50px 0px;
}

.questionnaire-headline {
  font-size: 25px;
  line-height: 3;
  color: #1b3f63;
}

.questionnaire-subheadline {
  line-height: 2;
  color: #8b9198;
}

.questionnaire-completed {
  border: 10px solid #8b9198;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  position: relative;
}

.questionnaire-completed .q-true {
  color: #8b9198;
}

.questionnaire-completed .q-false {
  color: #8b9198;
}

.help-overlay {
  position: absolute;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 20px;
  max-width: 350px;
  padding: 30px;
  min-height: calc(90vh);
  top: 79px;
  z-index: 10000;
  right: 0;
}

.help-overlay:before {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  border-top: 1px solid #dfdfdf;
  border-right: 1px solid #dfdfdf;
  background-color: #fff;
  top: -13px;
  right: 100px;
  transform: rotate(-45deg);
}

.help-hide {
  display: none;
}

.help-show {
  display: block;
}

.help-close {
  position: absolute;
  top: 10px;
  right: 10px;
}

.help-close i {
  color: #8b9198;
}

.help-headline {
  font-weight: 500;
  font-size: 25px;
  color: #1b3f63;
  border-bottom: 2px solid #eeeeee;
}

.headlineTitle {
  font-size: $ja-headline-title-font-size;
  line-height: $ja-headline-title-line-height;
  color: $ja-headline-title-color;
  font-weight: $ja-headline-title-font-weight;
  font-style: $ja-headline-title-font-style;
}

.faqHeadlineSubtitle {
  font-size: $ja-headline-subtitle-font-size;
  line-height: $ja-headline-subtitle-line-height;
  color: $ja-headline-subtitle-color;
  font-weight: $ja-headline-title-font-weight;
  font-style: $ja-headline-title-font-style;
}

.reset-headline,
.new-headline {
  font-size: 25px;
  color: #1b3f63;
}

.reset-subheadline,
.new-subheadline {
  color: #8b9198;
}

.reset-email span,
.new-password span,
.new-password-repeat span {
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #1b3f63;
}

.reset-email input,
.new-password-repeat input,
.new-password input {
  border: 2px solid #919fb3;
  box-sizing: border-box;
  border-radius: 50px;
  padding: 5px 10px;
  font-size: 20px;
  width: 70%;
}

#reset-password > .row:nth-child(3),
#new-password > .row:nth-child(3) {
  margin-top: 30px;
}

#reset-password > .row:nth-child(3) > div,
#new-password > .row:nth-child(3) > div {
  margin-bottom: 20px;
}

.new-password > div:first-child,
.new-password-repeat > div:first-child {
  line-height: 3;
}

.input--file {
  position: relative;
  color: #7f7f7f;
}

.input--file input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

#admin-dashboard-main > div:first-child {
  //margin-bottom: 20px;
  font-weight: 500;
  font-size: 25px;
  //line-height: 29px;
  color: #8b9198;
}

#admin-dashboard-main > div:not(:first-child) {
  //margin-bottom: 20px;
}

#admin-dashboard-main a {
  text-decoration: none;
  color: #8b9198;
}

#admin-dashboard-main a > div:first-child {
  font-weight: bold;
  font-size: 20px;
  //line-height: 23px;
  text-align: center;
  border-bottom: 2px solid #eeeeee;
  //padding-bottom: 10px;
  //margin-bottom: 15px;
}

#admin-dashboard-main a > div:nth-child(2) {
  text-align: center;
  //margin: 40px 0px;
}

#admin-dashboard-main a > div:nth-child(2) > i {
  color: #dfdfdf;
}

.text-middle-line {
  line-height: 2.5;
}

.quick-card-block {
  background: #ffffff;
  border: 3px solid #dfdfdf;
  border-radius: 20px;
}

.register-content-layer {
  margin-top: 10px;
}

.register-info {
  font-size: 20px;
}

.register-content-info-block-main {
  margin: 50px 0px;
}

.register-content-info-block {
  margin-top: 40px;
}

.clarification-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.no-quickcard-data-patient {
  color: #8b9198;
  width: 90%;
  margin: auto;
}

.no-quickcard-data-patient > span {
  margin-right: 5px;
}

.no-quickcard-data-patient > span:last-child {
  text-decoration: underline;
}

/*.card-headline-extra {
  margin: auto;
  width: 66%;
  top: 35%;
  position: absolute;
  left: 18%;
}*/

.flex-fill-two {
  max-width: 75%;
  margin: auto;
}

.access-code-admin-look {
  margin-right: 10px;
  position: relative;
  width: 140px;
}

.access-code-admin-look span {
  position: absolute;
  margin-top: 5px;
}

.edit-link-admin {
  width: 35px;
  height: 35px;
}

.d-flex.justify-content-center.align-items-center span {
  margin-left: 10px;
}

.modal-header-registration-info {
  background-color: #2278b9 !important;
}

.Toastify__toast-container--bottom-right {
  z-index: 1000000 !important;
}

#registration-info .modal-content {
  position: absolute;
  border-radius: 15px !important;
  z-index: 1000000000000 !important;
}

#registration-info .modal-header {
  border-radius: 12.5px 12.5px 0 0 !important;
}

#registration-info .close {
  color: #fff !important;
  opacity: 1 !important;
  text-shadow: none !important;
  padding: 0 !important;
  margin: 0 !important;
  position: absolute;
  top: 10px;
  right: 10px;
}

#registration-info .modal-body .row:last-child {
  margin: 20px 0px;
}

#registration-info .modal-body .row:last-child > div {
  margin: 10px 0px;
}

#registration-info .modal-body .row:last-child > div:nth-child(odd) {
  font-weight: bold;
  color: #1b3f63;
}

#registration-info .modal-body .row:last-child > div:nth-child(even) {
  color: #8b9198;
  word-break: break-word;
}

.access-code-professional {
  position: relative;
  margin-bottom: 15px;
}

.access-code-professional span {
  position: absolute;
  margin-top: 5px;
}

.copy-link-text {
  margin-top: 10px;
  font-weight: bold;
}

.quickcard-icon-data {
  width: 32px !important;
  height: 32px !important;
}

.quickcard-divider-overview {
  border-top: 1px solid #dfdfdf;
  border-radius: 0 !important;
}

.quickcard-shadow-box {
  box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.3);
}

.orange-shadow-box {
  box-shadow: 0px 0px 6px 2px rgba(253, 146, 104, 0.3);
  -webkit-box-shadow: 0px 0px 6px 2px rgba(253, 146, 104, 0.3);
  -moz-box-shadow: 0px 0px 6px 2px rgba(253, 146, 104, 0.3);
}

.quick-action-link > a,
.profile-shares-link > a {
  text-decoration: none;
}

.help-overlay-context > div {
  margin: 25px 0px;
}

/* Professional card */

.pcard-border {
  border: 2px solid #8b9198;
}

.pcard-bgColor {
  background-color: #8b9198;
}


.pcard-min-height {
  min-height: $user-card-min-height;
}

.pcard-textColor {
  color: #8b9198;
}

.pcard-title {
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.pcard-header {
  border-radius: 17px 17px 0 0;
  padding: 10px 0;
  //border: 1px solid #8B9198;
}

.pcard-body {
  padding: 0.75rem 1rem;
  color: #8b9198;
  background-color: #ffffff !important;
  border-radius: 0 0 20px 20px;
}

.overflow-customize::-webkit-scrollbar {
  display: none;
}

.overflow-customize {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.badge.position-absolute {
  right: 15px;
}

#download-list h5 {
  margin-bottom: 20px;
}

#download-list li {
  color: #8b9198;
  margin-bottom: 10px;
}

#download-list li a {
  text-decoration: none;
  color: #8b9198;
  font-weight: bold;
}

.middle-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50px, -50px);
}

.q-border-error {
  border: 2px solid #ff7777 !important;
}

.read-comment-container {
  margin-bottom: 10px;
}

.attachment--details {
  max-width: 100% !important;
}

.maintenance {
  margin: 20px 0px;
}

.maintenance-edit {
  margin-bottom: 0.5rem !important;
  margin-top: 1.5rem !important;
  padding-right: 15px;
  padding-left: 15px;
  word-break: break-word;
}

.card-create-action {
  margin-top: 10px;
  margin-bottom: 10px;
}

.read-comment {
  border-radius: 0 !important;
  overflow: hidden;
  word-break: break-word;
  margin-bottom: 5px;
  height: auto;
  white-space: pre-wrap;
}

.edit-comment {
  position: relative !important;
  top: 0 !important;
  bottom: -5px !important;
  left: 0px !important;
  display: inline-block;
  width: 85% !important;
}

.readable-text {
  word-break: break-word !important;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .card-body > .row > div {
    margin-bottom: 10px;
  }
  .footer {
    width: 100vw;
  }
  #registration-info .modal-body .row:last-child > div:nth-child(odd) {
    text-align: left !important;
  }
}

.disabled-div {
  pointer-events: none;
}

@media (max-width: 810px) {
  .d-flex.justify-content-center.align-items-center span {
    display: block;
  }
  .access-code-admin-look {
    width: auto;
  }
}

.profile-edit-share {
  margin-top: 20px;
}

.profile-headline-detail {
  font-size: 25px;
  color: #1b3f63;
  font-weight: 400;
}

.profile-headline-contact {
  font-size: 25px;
  color: #1b3f63;
}

.profile-headline-edit-share {
  font-size: 25px;
  color: #1b3f63;
}

.profile-edit-button,
.profile-share-button {
  background: #2178b9;
  border-radius: 25px;
  max-width: 75%;
  color: #fff;
  padding: 10px;
  text-align: center;
  margin: 10px auto;
}

.profile-block-contact-edit-share {
  background: #ffffff;
  border: 2px solid #dfdfdf;
  box-sizing: border-box;
  border-radius: 20px;
  padding: 15px;
}

.profile-sharing-main-data {
  padding-left: 25px !important;
}

.profile-organization-logo {
  min-width: 150px;
  min-height: 150px;
  max-width: 250px;
  max-height: 250px;
  border-radius: 20px;
  box-sizing: border-box;
  border: 2px solid #8e929a;
  object-fit: cover;
}

.logo-organisation-img {
  max-width: 180px;
  max-height: 180px;
  text-align: center;
}

.profile-img-avatar {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  background-color: #dfdfdf;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.user-profile-img-avatar {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  color: #fff;
  background-color: #8B9198;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}

.profile-btn-size {
  width: 120px;
}

.help-overlay-link-view {
  cursor: pointer;
}

.register-second-step-password {
  margin-bottom: 16px;
}

.medical-plan-card,
.patient-access-codes,
.patient-shared-profile {
  margin-top: 10px;
  margin-bottom: 10px;
}

.quickcard-card-data,
.access-code-patient {
  max-width: 80%;
}

.quickcard-nav-tab {
  position: relative;
}

.admin-reset-password,
.admin-reset-password:hover,
.admin-reset-password:visited,
.admin-reset-password:link {
  text-decoration: none;
}

.set-overflow-y {
  overflow-y: auto;
}

.medical-plan-icon {
  color: #dfdfdf !important;
}

.medical-plan-text {
  color: #8b9198;
  margin-top: 10px;
}

.quickcard-size {
  min-width: $quickcard-min-width;
  max-width: $quickcard-max-width;
  min-height: $quickcard-min-height;
}

.user-card-size {
  min-width: $user-card-min-width;
  max-width: $user-card-max-width;
  min-height: $user-card-min-height;
}

.erstellen-card-size {
  min-width: $user-card-min-width;
  max-width: $user-card-max-width;
  max-height: 200px;
  min-height: 200px;
}

.erstellen-card-border {
  border: 2px solid #DFDFDF !important;
}

.ellipse-text {
  white-space: nowrap;
  text-overflow: ellipsis;
}

.medical-plan-text {
  color: #8b9198;
  margin-top: 10px;
}

.medical-plan-download-file {
  max-width: 100% !important;
}

.comment-input {
  border-radius: 25px !important;
  padding-bottom: 100px !important;
  padding-top: 15px !important;
  background-color: transparent !important;
}

.comment-tele {
  border-radius: 25px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  background-color: transparent !important;
}

.link-tele {
  border-radius: 25px !important;
  height: 60px !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  background-color: transparent !important;
  width: auto;
}

.scroll::-webkit-scrollbar {
  display: none;
}

.modal-body {
  word-break: break-word !important;
}

.patient-icon-size {
  font-size: 40px;
}

.assessment-card-size {
  min-height: 280px;
  min-width: 250px;
  max-width: 350px;
}

.modal-body-size {
  width: 100%;
  height: 180px;
}

.Toastify__toast-container {
  width: fit-content !important;
}

.Toastify__close-button > svg {
  // fill: currentColor;
  height: 20px !important;
  width: 20px !important;
}

.Toastify__close-button {
  padding-right: 8px !important;
  align-self: center !important;
}

.Toastify__toast {
  margin-bottom: 1rem;
  padding: 8px;
  border-radius: 50px 50px !important;
}

.Toastify__toast-icon {
  margin-inline-end: 10px;
  width: 30px !important;
}

.mapboxgl-ctrl-geocoder {
  position: absolute;
  top: 5%;
  right: 3%;
}

.suggestions-wrapper {
  background-color: white;
  width: 200px;
}

.suggestions {
  list-style: none;
  padding-left: 5px;
}

.suggestions li:hover {
  cursor: pointer;
  background-color: #2178b9;
}

.geocoder-pin-right {
  display: none;
}

.incomplete-questionnaire-title {
  background: #fd9268;
}

.incomplete-teleconsult-title {
  background: #2178b9;
}

@media (max-width: 825px) {
  .profile-sharing-access-code input,
  .reset-email input,
  .new-password-repeat input,
  .new-password input {
    width: 50%;
    margin: auto;
  }
}

@media (max-width: 991px) {
  .card-body > .row > div {
    margin-bottom: 10px;
  }
  .card-row > div:last-child {
    min-height: 150px;
  }
  .card-main-downloads-list {
    border-radius: 20px 20px 0px 0px !important;
  }
  .card-main-downloads {
    min-height: 50px;
  }
  /*.card-headline-extra {
    margin: auto;
    width: 100%;
    top: 0;
    left: 0;
    position: relative;
  }*/
  .card-main-downloads-row > .card-headline {
    background-color: #dfdfdf;
    color: #8b9198;
    font-weight: bold;
    text-align: center;
    padding: 15px 0px;
    border-radius: 20px 20px 0px 0px;
  }
  .card-row {
    border: 2px solid #dfdfdf;
    border-radius: 25px;
  }
  .card-comment {
    color: #8b9198;
    font-weight: bold;
    padding: 10px;
    text-align: left;
  }
  .profile-block-detail > div:nth-child(2) {
    margin-top: 20px;
  }

  //.profile-sharing-access-code,
  .reset-email {
    width: 100%;
    margin: auto;
  }
  .help-overlay:before {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    border-top: 1px solid #dfdfdf;
    border-right: 1px solid #dfdfdf;
    background-color: #fff;
    top: -13px;
    right: 85px;
    transform: rotate(-45deg);
  }
}

@media (min-width: 992px) and (max-width: 1020px) {
  .card-main-downloads {
    min-height: 480px;
    border: 2px solid #dfdfdf;
    border-radius: 25px;
  }

}

@media (max-width: 576px) {
  .login-container {
    width: 90%;
    height: 70%;
  }
  .login-form-container {
    width: 100%;
  }
  .map-view-container {
    flex-direction: column-reverse;
  }
  .mapboxgl-ctrl-geocoder {
    position: absolute;
    top: 0.2%;
    right: 1%;
  }

  .mapboxgl-canvas {
    height: 50%;
  }
}

@media (min-width: 577px) and (max-width: 962px) {
  .login-container {
    width: 75%;
    height: 60%;
  }
  .login-form-container {
    width: 60%;
  }
  .map-view-container {
    flex-direction: column-reverse;
  }
  .mapboxgl-ctrl-geocoder {
    position: absolute;
    top: 0.2%;
    right: 1%;
  }
}

@media (min-width: 963px) and (max-width: 1200px) {
  .login-container {
    width: 50%;
    height: 70%;
  }
  .login-form-container {
    width: 60%;
  }
}

@media (min-width: 1201px) and (max-width: 1440px) {
  .login-container {
    width: 90%;
    height: 60%;
  }
  .login-form-container {
    width: 60%;
  }
}

@media (min-width: 1441px) and (max-width: 1980px) {
  .login-container {
    width: 80%;
    height: 50%;
  }
  .login-form-container {
    width: 50%;
  }
}

@media (min-width: 1981px) {
  .login-container {
    width: 50%;
    height: 40%;
  }
  .login-form-container {
    width: 50%;
  }
}

@media (min-width: 576px) and (min-height: 800px) and (orientation: portrait) {
  .login-container {
    width: 70%;
    height: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  #page-content-wrapper > div > div.row.mt-4 > div {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .card-min-height {
    min-height: 350px;
  }
  .quickcard-patient-overview {
    height: 120px;
  }
}

//DOWNLOAD PORTAL
$green: #488E79;
$yellow: #FFC000;
$blue: #5DA2E7;
$gray: #8B9198;
$white: #FFFFFF;

@mixin btnLargeScreen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 187px;
  height: 59px;
  margin-right: 34px;
  .downloadIcon {
    margin-right: 10px;
    width: 34.52px;
    max-height: 35.98px;
  }
}

@mixin btnSmallScreen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 139px;
  height: 88px;
  .downloadIcon {
    width: 34.52px;
    max-height: 35.98px;
  }
}

.buttonSearchDiv {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 25px 0;
}

@mixin btnLayout {
  @media (min-width: 577px) {
    @include btnLargeScreen;
  }
  @media (max-width: 577px) {
    @include btnSmallScreen;
  }
  padding: 12px 16px;
  position: static;
  margin-bottom: 28px;
  left: 0;
  top: 0;
  border: 2px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  border-radius: 41px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;
}

// DOWNLOAD PORTAL

#buttonNavi div:nth-child(1) {
  @include btnLayout();
  border-color: $green;
  color: $gray;
}

#buttonNavi div.active:nth-child(1) {
  @include btnLayout();
  box-shadow: none;
  background: $green;
  border-color: $green;
  color: $white;
}

#buttonNavi div:nth-child(2) {
  @include btnLayout();
  background: rgba(255, 192, 0, 0.06);
  border-color: $yellow;
  color: $gray;
}

#buttonNavi div.active:nth-child(2) {
  @include btnLayout();
  box-shadow: none;
  background: $yellow;
  border-color: $yellow;
  color: $white;
}

#buttonNavi div:nth-child(3) {
  @include btnLayout();
  background: rgba(93, 162, 231, 0.06);
  border-color: $blue;
  color: $gray;
}

#buttonNavi div.active:nth-child(3) {
  @include btnLayout();
  box-shadow: none;
  background: $blue;
  border-color: $blue;
  color: $white;
}

#buttonNavi div:nth-child(4) {
  @include btnLayout();
  background: rgba(139, 145, 152, 0.06);
  border-color: $gray;
  color: $gray;
}

#buttonNavi div.active:nth-child(4) {
  @include btnLayout();
  box-shadow: none;
  background: $gray;
  border-color: $gray;
  color: $white;
}


//VIDEO PORTAL
//allgemein
#buttonNavigation div:nth-child(1) {
  @include btnLayout();
  background: rgba(139, 145, 152, 0.06);
  border-color: $gray;
  color: $gray;
}

#buttonNavigation div.active:nth-child(1) {
  @include btnLayout();
  box-shadow: none;
  background: $gray;
  border-color: $gray;
  color: $white;
}

//physio
#buttonNavigation div:nth-child(2) {
  @include btnLayout();
  border-color: $green;
  color: $gray;
}

#buttonNavigation div.active:nth-child(2) {
  @include btnLayout();
  box-shadow: none;
  background: $green;
  border-color: $green;
  color: $white;
}

//ergo
#buttonNavigation div:nth-child(3) {
  @include btnLayout();
  background: rgba(255, 192, 0, 0.06);
  border-color: $yellow;
  color: $gray;
}

#buttonNavigation div.active:nth-child(3) {
  @include btnLayout();
  box-shadow: none;
  background: $yellow;
  border-color: $yellow;
  color: $white;
}

//logo
#buttonNavigation div:nth-child(4) {
  @include btnLayout();
  background: rgba(93, 162, 231, 0.06);
  border-color: $blue;
  color: $gray;
}

#buttonNavigation div.active:nth-child(4) {
  @include btnLayout();
  box-shadow: none;
  background: $blue;
  border-color: $blue;
  color: $white;
}

#physio.active,
#occupational.active,
#speech.active,
#other.active {
  display: block;
}

#physio.hidden,
#occupational.hidden,
#speech.hidden,
#other.hidden {
  display: none;
}
